@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}

.bottomBanner {
  position: fixed;
  bottom: 4rem;
  left: 2rem;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  animation: fade-in 0.3s 1s forwards;
  // a {
  //   &:hover {
  //     opacity: 1;
  //   }
  // }
  button {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 0rem;
    top: -2.4rem;
    border-radius: 50%;
    background-color: white;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    & > span {
      width: 50%;
      height: 0.1rem;
      position: relative;
      & > span {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--black6);
        &:nth-of-type(1) {
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          transform: rotate(45deg);
        }
      }
    }
  }
}

.box {
  width: 31rem;
  border: 1px solid;
  background-color: white;
  color: #191f4d;
}

.topText {
  line-height: 2.5;
  font-size: 1.9rem;
  font-weight: bold;
  text-align: center;
}

.list {
  padding: 0 1.6rem;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.itemLink {
  line-height: 2.8;
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #eee;
  color: #191f4d;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 1.3rem;
    height: 1.3rem;
    margin-left: -0.9rem;
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: #bbb;
    transform: rotate(45deg);
    right: 1rem;
    top: 32%;
  }
}

.bottomLink {
  width: 28rem;
  text-align: center;
  margin: 0.8rem auto 1.6rem;
  line-height: 2.2;
  border: 1px solid;
  color: #00acba;
  font-size: 1.4rem;
}
@media screen and (max-width: 600px) {
  .bottomBanner {
    display: none;
  }
}
